import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import styles from "../css/template.module.css"
import Img from "gatsby-image"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const Template = ({ data }) => {
  const {
    name,
    url,
    released,
    description: { description },
    images,
  } = data.episode

  const [mainImage, ...episodeImages] = images

  const iframeStyle = {
    width: "100%",
    height: "600px",
    top: "0",
    left: "0",
  }

  return (
    <Layout>
      <SEO title={name} />
      <StyledHero img={mainImage.fluid} />
      <section className={styles.template}>
        <div className={styles.center}>
          <div className={styles.images}>
            {episodeImages.map((item, index) => {
              return (
                <Img
                  key={index}
                  fluid={item.fluid}
                  alt="single episode"
                  className={styles.image}
                />
              )
            })}
          </div>
          <h2>{name}</h2>
          <h4>Released on: {released}</h4>
          <p className={styles.desc}>{description}</p>
          <div>
            <iframe
              title={name}
              src={url}
              frameBorder="0"
              allowFullScreen
              style={iframeStyle}
            ></iframe>
          </div>
          <div className={styles.centerButton}>
            <Link to="/episodes" className="btn-primary">
              back to episodes
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    episode: contentfulEpisode(slug: { eq: $slug }) {
      name
      slug
      url
      released(formatString: "ddd, MMMM Do, YYYY")
      description {
        description
      }
      images {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

export default Template
